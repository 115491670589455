/*
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
               Page Flip
%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%
*/

jQuery(document).on('ready', function() {

    var $ = jQuery.noConflict();

    var Page = (function() {

        var $grid = $('.ccr-split-box');
        var $curItem = 0;
        var $hkBullets = $('.pager .bullets > span');

        return {
            init: function() {
                $grid.find('div.bb-bookblock').each(function(i) {

                    var $bookBlock = $(this),
                        $nav = $('.pager > span > span'),
                        bb = $bookBlock.bookblock({
                            speed: 1500,
                            interval: 1000,
                            nextEl: '.pager .cycle-next',
                            prevEl: '.pager .cycle-prev',
                            shadows : true,
                            circular : true,
                            onEndFlip : function(old, page, isLimit) {
                                $curItem = page;
                                $hkBullets.removeClass('bb-current').eq($curItem).addClass('bb-current');
                                return false;
                            },
                            //shadowSides : 1,
                            //shadowFlip : 0.5,
                        });

                    // add navigation events
                    $nav.each(function(i) {
                        $(this).on('click touchstart', function(event) {
                            var $dot = $(this);
                            $nav.removeClass('bb-current');
                            $dot.addClass('bb-current');
                            $bookBlock.bookblock('jump', i + 1);
                            return false;
                        });
                    });

                    // add swipe events
                    $bookBlock.children().on({
                        'swipeleft': function(event) {
                            $bookBlock.bookblock('next');
                            return false;
                        },
                        'swiperight': function(event) {
                            $bookBlock.bookblock('prev');
                            return false;
                        }

                    });

                });

                // added to handle clicking on P/N and setting dot current


            }
        };

    })();

    Page.init();

    $('.gallery-item.bb-item > figcaption').click(function(){
        $('.pager .cycle-prev').click();
    });

    $('.gallery-item.bb-item > div').click(function(){
        $('.pager .cycle-next').click();
    });

}); // end on doc ready
